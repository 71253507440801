
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import PostaKodu from "@/components/inputs/PostaKodu.vue";
import {AdresTuru} from '@/enum/AdresTuru';
import {IlceEntity} from "@/entity/IlceEntity";

@Component({
  components: {
    PostaKodu,
    FormWrapper,
    AdresTuruPicker,
    SehirPicker,
    IlcePicker,
  }
})
export default class AdresForm extends Mixins(ObjectInputMixin) {
  @Prop() action!: string;
  @Prop() hideButton: any;
  @Prop() formYazdir!: string | boolean;
  @Prop({}) successOnValidate !: any;
  @Prop({default:false}) nonRequired !: Boolean;

  @Prop () yabanciUyruklu!:string|boolean

  secilenIlce = new IlceEntity();
  adresTuru = AdresTuru;

  @Watch('secilenIlce')
  onSecilenIlceChange() {
    this.$emit('ilceName', this.secilenIlce.isim);
  }


  get isYabanciUyruklu(){
    return this.yabanciUyruklu||this.yabanciUyruklu==''
  }

  get isFormYazdir() {
    return this.formYazdir || this.formYazdir === "";
  }

  get adresBilgisiRule(){
    if (this.localValue.adres) {
      if (this.localValue.adres.match(/["'<>&]/g) == null)
        return true;
      else
        return `Yasak karakter. ", ', <, >, & karakterleri kullanılamaz.`;
    } else
      return true;
  }

  get requiredRule() {
    return this.nonRequired ? [] :[(v:any) => !!v || 'Lütfen bu alanı doldurun.']
  }

  protected onAdresTuruChange() {
    this.input();
    if (this.localValue.adres_tur_id === 1 || this.localValue.adres_tur_id === 9) {
      this.localValue.sehir_id = null;
      this.localValue.ilce_id = null;
      this.localValue.adres = null;
      this.localValue.posta_kodu = null;
    }
  }

  get localAction() {
    if (this.localValue && this.localValue.id) {
      return "/api/v1/adres";
    }
    return this.action;
  }
}
